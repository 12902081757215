
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { datoToBRICKS, Story } from '@titelmedia/bricks';
import { cloneDeep, forEach } from 'lodash';

const Page = ({ data }) => {
  const { allDatoCmsStory } = data;
  const storyNode = allDatoCmsStory.edges.length
    ? allDatoCmsStory.edges[0].node
    : null;
  const settings = {
    shouldDisplayHeader: storyNode.displayHeader,
    shouldDisplayArticleFooter: storyNode.displayArticleFooter,
    shouldDisplayPresentedBy: storyNode.displayPresentedBy,
  };

  const customCss = storyNode.devCustomCss + ' code {display: none;} footer {display: none!important;} [id^="footer"] {display: block!important;}';
  const story = datoToBRICKS({ content: storyNode.storyContent });

  //temporary fix to parse custom props as object vs string
  let myStory = cloneDeep(story);
  if (story[0].elements[0].data.customProps) {
    myStory[0].elements[0].data.customProps = JSON.parse(
      story[0].elements[0].data.customProps,
    );
  }

  forEach(myStory, (layout, ltIndex) => {
    if (layout.elements) {
      forEach(layout.elements, (el, elIndex) => {
        if (el.data.customProps) {
          myStory[ltIndex].elements[elIndex].data.customProps = JSON.parse(
            el.data.customProps,
          );
        }

        if (el.data.items) {
          forEach(el.data.items, (item, itemIndex) => {
            if (item.customProps) {
              myStory[ltIndex].elements[elIndex].data.items[
                itemIndex
              ].customProps = JSON.parse(item.customProps);
            }
          });
        }
      });
    }
  });

  // move footer into smooth scroll div ;)
  useEffect(() => {
    // Could be the HS footer and Article footer, if available
    const footers = document.querySelectorAll('footer');
    const smoothScrollContainer = document.querySelector('#smooth-content');

    footers.forEach((footer, index) => {
      const footerClone = footer.cloneNode(true);
      footerClone.id = 'footer' + index;
      smoothScrollContainer.appendChild(footerClone);
      footer.style.display = 'none!important';
      footer.parentNode.removeChild(footer);
    });
  }, []);

  return (
    <React.Fragment>
      <Story story={myStory} useSmoothScroll={storyNode.useSmoothScroll} useColorTransitions={storyNode.useColorTransitions}/>
      <code dangerouslySetInnerHTML={{ __html: JSON.stringify(settings) }} />
      <style dangerouslySetInnerHTML={{ __html: customCss }} />
    </React.Fragment>
  );
};

export default Page;
export const query = graphql`
  query StoryQuery {
    allDatoCmsStory(filter: { id: { eq: "DatoCmsStory-165390023-en" } }) {
      edges {
        node {
          id
          multi
          displayHeader
          displayArticleFooter
          displayPresentedBy
          useSmoothScroll
          useColorTransitions
          devCustomCss
          storyContent {
            ... on DatoCmsHero {
              id
              __typename
              title
              backgroundImage {
                fluid(imgixParams: {maxW: 1920}) {
                  srcSet
                  src
                }
              }
              customProps
              textColor {
                hex
              }
              vimeoId
              svgPortrait {
                url
              }
              svgLandscape {
                url
              }
              horizontalAlignment
              verticalAlignment
              typeface
              blackOverlay
              categoryTitle
              categoryUrl
              editor
              editorUrl
              company
            }
            ... on DatoCmsProgressBar {
              id
              color {
                hex
              }
              progressPosition
              progressSize
              __typename
            }
            ... on DatoCmsCustomMarkup {
              html
              __typename
              id
            }
            ... on DatoCmsSvg {
              id
              useContainer
              svgLandscape {
                url
              }
              svgPortrait {
                url
              }
              rotateNumber
              rotationDirection
              enableRotation
              __typename
            }
            ... on DatoCmsLoader {
              id
              __typename
              backgroundColor {
                hex
              }
              logoColor {
                hex
              }
            }
            ... on DatoCmsLayoutDivider {
              id
              title
              backgroundColor {
                hex
              }
              textColor {
                hex
              }
              layoutType
              vimeoId
              colorMode
              __typename
              backgroundImage {
                fluid(imgixParams: {maxW: 1920}) {
                  srcSet
                  src
                  aspectRatio
                }
              }
            }
            ... on DatoCmsParagraph {
              id
              __typename
              fontColor {
                hex
              }
              fontStyle
              lineWidth
              textAlignment
              setAnimationDuration
              enableAnimation
              text
            }
             ... on DatoCmsHeadline {
              id
              containerWidth
              customFontSizeDesktop
              customFontSizeMobile
              fontColor {
                hex
              }
              fontSize
              fontStyle
              text
              textAlignment
              setAnimationDuration
              enableAnimation
              __typename
            }
            ... on DatoCmsMarquee {
              id
              images {
                fluid(imgixParams: {maxW: 1200}) {
                  srcSet
                  src
                  aspectRatio
                }
              }
              svg {
                url
              }
              text
              fontWeight
              fontColor {
                hex
              }
              backgroundColor {
                hex
              }
              desktopHeight
              mobileHeight
              direction
              marqueeDuration
              triggerScrub
              __typename
            }
            ... on DatoCmsSeparator {
              id
              __typename
              paddingSize
              applyToDevice
            }
            ... on DatoCmsQuote {
              id
              __typename
              text
              fontColor {
                hex
              }
              fontWeight
              lineWidth
              enableAnimation
              setAnimationDuration
            }
            ... on DatoCmsAgeGate {
              id
              logo {
                fluid(imgixParams: {maxW: 1200}) {
                  srcSet
                  src
                  aspectRatio
                }
              }
              backgroundColor {
                hex
              }
              title
              yesMessage
              noMessage
              redirectLink
              colorMode
              __typename
            }
            ... on DatoCmsImage {
              id
              __typename
              useContainer
              usePadding
              galleryType
              setAnimationDuration
              enableAnimation
              images {
                image {
                  alt
                  fluid(imgixParams: {maxW: 1920}) {
                    srcSet
                    src
                    aspectRatio
                  }
                }
                imageMobile {
                  alt
                  fluid(imgixParams: {maxW: 1920}) {
                    srcSet
                    src
                    aspectRatio
                  }
                }
                absoluteImage {
                  alt
                  fluid(imgixParams: {maxW: 1920}) {
                    srcSet
                    src
                    aspectRatio
                  }
                }
                motionBackgroundImg {
                  alt
                  fluid(imgixParams: {maxW: 1920}) {
                    srcSet
                    src
                    aspectRatio
                  }
                }
                enableRotation
                setStartRotation
                rotationDirection
                rotateNumber
                imageAlignment
                imagePosition
                parallax
                parallaxNumber
                link
                svg {
                  url
                }
                svgPosition
                credits {
                  id
                  text
                  alignment
                  creditsColor {
                    hex
                  }
                  padding
                }
              }
            }
            ... on DatoCmsInterview {
              id
              __typename
              answer
              question
              positioning
              sticky
              fontColor {
                hex
              }
              fontWeight
              fontStyle
              setAnimationDuration
              enableAnimation
            }
            ... on DatoCmsCta {
              id
              __typename
              advanced
              backgroundColor {
                hex
              }
              backgroundColorHover {
                hex
              }
              border
              buttonText
              text
              textColor {
                hex
              }
              textColorHover {
                hex
              }
              fontWeight
              url
              setAnimationDuration
              enableAnimation
            }
            ... on DatoCmsVideo {
              id
              __typename
              videoType
              usePadding
              providerUid
              aspectRatio
              setPosterImage
              posterImage {
                fluid(imgixParams: {maxW: 1200}) {
                  srcSet
                  src
                  aspectRatio
                }
              }
              autoplay
              autopause
              muted
              loop
              hideControls
              controlColors {
                hex
              }
            }
            ... on DatoCmsCredit {
              id
              creditsTexts {
                id
                category
                title
                name
                link
              }
              setAnimationDuration
              enableAnimation
              __typename
            }
            ... on DatoCmsShoppingCarousel {
              id
              currency
              shoppingProducts {
                id
                image {
                  fluid(imgixParams: {maxW: 1200}) {
                    srcSet
                    src
                    aspectRatio
                  }
                }
                price
                name
                brand
                url
              }
              __typename
            }
            ... on DatoCmsGalleryFunky {
              id
              __typename
              galleryType
              customProps
              items {
                id
                item {
                  fluid(imgixParams: {maxW: 1200}) {
                    srcSet
                    src
                    aspectRatio
                  }
                }
                customProps
              }
            }
            ... on DatoCmsGallery {
              id
              __typename
              galleryType
              items {
                alt
                fluid(imgixParams: {maxW: 1200}) {
                  src
                  srcSet
                  aspectRatio
                  height
                  width
                }
              }
              staticImage {
                alt
                fluid {
                  src
                  srcSet
                  aspectRatio
                }
              }
              staticPosition
              overlayHorizontal
              overlayVertical
              overlayAlignment
              setAnimationDuration
              enableAnimation
            }
            ... on DatoCmsImagetext {
              id
              __typename
              image {
                alt
                fluid(imgixParams: {maxW: 1200}) {
                  src
                  srcSet
                  aspectRatio
                }
              }
              imageMobile {
                alt
                fluid(imgixParams: {maxW: 1200}) {
                  src
                  srcSet
                  aspectRatio
                }
              }
              headline
              headlineColor {
                hex
              }
              text
              bodyColor {
                hex
              }
              textWrap
              sticky
              parallax
              parallaxNumber
              fullWidth
              imageAlignment
            }
            ... on DatoCmsArticleAggregator {
              id
              title
              titleImage {
                alt
                fluid(imgixParams: {maxW: 1200}) {
                  src
                  srcSet
                  aspectRatio
                }
              }
              titleMobileImage {
                alt
                fluid(imgixParams: {maxW: 1200}) {
                  src
                  srcSet
                  aspectRatio
                }
              }
              subtitle
              headingOverlay
              blackOverlay
              textPosition
              cardTextPosition
              fontColor {
                hex
              }
              scrollCue
              hoverState
              imageFit
              articleAggregatorItems {
                id
                image {
                  alt
                  fluid(imgixParams: {maxW: 1200}) {
                    src
                    srcSet
                    aspectRatio
                  }
                }
                mobileImage {
                  alt
                  fluid(imgixParams: {maxW: 1200}) {
                    src
                    srcSet
                    aspectRatio
                  }
                }
                title
                subtitle
                cta
                url
              }
              __typename
            }
          }
        }
      }
    }
  }
`;
